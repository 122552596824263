<template>
  <v-dialog v-model="dialog" width="1100" :persistent="isSending">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs" />
    </template>

    <v-card height="90vh">
      <v-stepper v-model="step" class="database-adder-stepper">
        <v-stepper-items style="position: relative;">
          <v-stepper-content class="stepper-content bg-ease" step="1">
            <DatabaseAdderStep1 ref="step1" />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

      <v-card-actions>
        <v-row class="px-8 py-4" no-gutters justify="space-between">
          <v-col cols="auto">
            <v-btn
              class="btn-cancel"
              :disabled="isSending"
              rounded
              @click="cancel"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              v-if="step > 1"
              class="mr-4"
              color="primary"
              dark
              :disabled="isSending"
              rounded
              @click="prevStep"
            >
              {{ $t('prev_step') }}
            </v-btn>
            <v-btn
              v-if="step < totalStep"
              color="primary"
              dark
              :disabled="isSending"
              rounded
              @click="nextStep"
            >
              {{ $t('next_step') }}
            </v-btn>
            <v-btn
              v-else
              color="primary"
              dark
              rounded
              :loading="isSending"
              @click="submit"
            >
              {{ $t('create') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DatabaseAdderStep1 from './DatabaseAdderStep1'

export default {
  name: 'DatabaseAdder',

  components: {
    DatabaseAdderStep1
  },

  data: () => ({
    dialog: false,
    isSending: false,
    step: 1,
    totalStep: 1
  }),

  computed: {
    stepRefs() {
      return Array.from({ length: this.totalStep }, (_, i) => `step${i + 1}`)
    }
  },

  methods: {
    init() {
      this.step = 1
      this.resetValidation()
      this.$store.dispatch('databases/form/init')
    },
    close() {
      this.dialog = false
    },
    cancel() {
      this.close()
      this.init()
    },
    resetValidation() {
      this.stepRefs.map((ref) => {
        return this.$refs?.[ref]?.resetValidation?.()
      })
    },
    async formValidate(ref) {
      const funValidate = this.$refs?.[ref]?.validate

      if (typeof funValidate !== 'function') {
        return true
      }

      return funValidate()
    },
    prevStep() {
      this.step -= 1
    },
    async nextStep() {
      const ref = `step${this.step}`
      const isValid = await this.formValidate(ref)

      if (!isValid) return

      this.step += 1
    },
    async submit() {
      const isValid = await Promise.all(
        this.stepRefs.map((ref) => this.formValidate(ref))
      ).then((results) => results.every(Boolean))

      if (!isValid) return

      this.isSending = true
      this.$store
        .dispatch('databases/form/submit')
        .then(() => {
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.$t('success_created')
          })
          this.$store.dispatch('databases/form/init')
          this.close()
          this.init()
          this.$emit('successAdded')
        })
        .catch((error) => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
        })
        .finally(() => {
          this.isSending = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.database-adder-stepper {
  height: calc(100% - 84px);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: none;

  .v-stepper__items {
    height: 100%;

    .stepper-content {
      padding: 0;
      height: 100%;

      ::v-deep .v-stepper__wrapper {
        height: 100%;
      }
    }
  }
}

.v-card__actions {
  & .v-btn.v-btn {
    padding: 4px 1.875rem;
    font-size: 1.25rem;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
    letter-spacing: normal;

    &.btn-cancel {
      color: #343843;
      border: solid 1px #ddd;
    }
  }
}
</style>
